<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="editFormVisible"
    :width="dialogWidth"
    class="dialog"
    :close-on-click-modal="false"
    @click="editFormVisible = false"
  >
    <el-form ref="editForm" label-position="left" label-width="120px" :class="{'dialog-form form-item-row' : column === '2'}" :model="formData">
      <slot name="dynamicInsert" />
      <el-form-item
        v-for="(item, index) in addAndEditFormItem"
        :key="index"
        :label="item.label"
        :prop="item.value"
        :rules="getRules(item.rules || item.type)"
      >
        <slot :name="item.slotName">
          <!-- input框 -->
          <el-input
            v-if="item.type === 'input'"
            v-model="formData[item.value]"
            auto-complete="off"
            :type="item.inputType"
            :disabled="item.disabled"
            :placeholder="`请输入${item.label}`"
            @input="$forceUpdate()"
          />
          <!-- 单选框 -->
          <div v-else-if="item.type === 'radio'">
            <el-radio
              v-for="(val, num) in item.child"
              :key="num"
              v-model="formData[item.value]"
              :label="val.value"
              :disabled="item.disabled"
              @change="$forceUpdate()"
            >
              {{ val.label }}
            </el-radio>
          </div>
          <!-- 复选框 -->
          <el-checkbox-group v-else-if="item.type === 'checkbox'" v-model="formData[item.value]" @change="$forceUpdate()">
            <el-checkbox
              v-for="(val, num) in item.child"
              :key="num"
              :label="val.value"
              :name="item.value"
              :disabled="item.disabled"
            >
              {{ val.label }}
            </el-checkbox>
          </el-checkbox-group>
          <!-- 下拉框 -->
          <el-select
            v-else-if="item.type === 'select'"
            v-model="formData[item.value]"
            :placeholder="`请选择${item.label}`"
            :disabled="item.disabled"
            :multiple="item.multiple"
            filterable
            @change="selectChange"
          >
            <el-option
              v-for="(val, num) in item.child"
              :key="num"
              :label="val[item.optionLabel]"
              :value="val[item.optionId]"
            />
          </el-select>
          <!-- 级联 -->
          <el-cascader
            v-else-if="item.type === 'cascader'"
            v-model="formData[item.value]"
            :props="item.props"
            :options="item.options"
            clearable
            @change="$forceUpdate()"
          />
          <!-- 时间窗 -->
          <el-time-picker
            v-else-if="item.type === 'timeSelect'"
            v-model="formData[item.value]"
            :placeholder="`请选择${item.label}`"
            :value-format="item.valueFormat"
            clearable
            @change="$forceUpdate()"
          />
          <!-- 日期时间窗 -->
          <el-date-picker
            v-else-if="item.type === 'dateSelect'"
            v-model="formData[item.value]"
            type="date"
            :placeholder="`请选择${item.label}`"
            :value-format="item.valueFormat"
            clearable
            @change="$forceUpdate()"
          />
        </slot>
      </el-form-item>
      <slot name="dynamicInsertAfter" />
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" plain class="widen-button" @click="editFormVisible = false">
        取消
      </el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        class="title widen-button"
        @click="submitForm('editForm')"
      >
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
/** *
 * type字段时设置为什么类型的  input为input框  radio 为单选项  select为下拉框  checkbox为复选框
 * value为要设置的属性
 * label为设置的名字
 * disabled 为禁用状态
 * rules为传入的验证规则   不验证的话是传入none   常规验证有加上  就不用写
 * optionLabel  optionId为设置子项的名字跟属性
 * child为当有其他属性的时候  value为要设置的属性  label为设置的名字
*/
export default {
  props: {
    // 表单项
    addAndEditFormItem: {
      default: () => [],
      type: Array
    },
    // 要修改的数据
    editFormData: {
      default: () => { return {} },
      type: Object
    },
    dialogWidth: {
      default: '',
      type: String
    },
    column: {
      default: '',
      type: String
    },
    needDialogTitle: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      dialogTitle: '添加',
      formData: {},
      loading: false,
      rules: {},
      editFormVisible: false
    }
  },
  watch: {
    editFormVisible: {
      handler(newVal) {
        if (!newVal) {
          this.formData = {}
        }
        this.$nextTick(() => {
          this.$refs['editForm'].clearValidate()
        })
      }
    },
    editFormData: {
      handler(newVal) {
        this.formData = { ...newVal }
        this.dialogTitle = newVal && newVal.id ? '编辑' : '添加'
      }
    }
  },
  methods: {
    // 根据情况传入验证项  如果是先传入rules 就直接return  如果没传  就系统给他默认   如果rules传入的是none就表示不需要传入验证
    getRules(type) {
      if (type instanceof Array) return type
      if (!type) return []
      switch (type) {
        case 'input':
          return [
            { required: true, message: '请输入', trigger: 'blur' }
          ]
        case 'none':
          return []
        default:
          return [
            { required: true, message: '请选择', trigger: 'change' }
          ]
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('getFormData', this.formData)
        }
      })
    },
    selectChange(val) {
      this.$emit('changeFormSelect', val)
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.dialog .el-select,
.dialog .el-cascader,
.dialog .el-input {
  width: 100%;
}
</style>
